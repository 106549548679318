:root {
  --cassie-tabs-container-border-radius: 5px;
  --cassie-tabs-container-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  --cassie-tab-font-size: 14px;
  --cassie-tab-text-transform: unset !important;
  --cassie-tab-opacity: 1 !important;
  --cassie-tab-active-border-radius: 5px;
  --cassie-tab-active-background-color: #1976d2;
  --cassie-tab-active-text-color: #fff !important;
  --cassie-tab-inactive-text-color: rgba(0, 0, 0, 0.54) !important;
  --cassie-tab-border-right: 2px solid #f1f1f5;
  --cassie-tab-secondary-background-color: #5fa2e6;
  --cassie-tab-secondary-text-color: #fff !important;
  --cassie-tab-step-active-background-color: #1976d2 !important;
  --cassie-tab-step-active-text-color: #fff !important;
  --cassie-tab-step-complete-background-color: #949fb6;
  --cassie-tab-step-complete-text: #fff !important;
}
