@import './shared/styles/_vuetify-overrides.scss';












































































































































































//
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

@import './styles';
// poppins font
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Poppins-Italic';
  src: url('./fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
}

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Style variables and utilities from src/styles.
*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html.prevent-scroll,
body.prevent-scroll {
  height: auto !important;
  padding-right: 17px;
  overflow-y: hidden !important;
}
html {
  overflow: hidden !important;
}

html,
body,
.v-application {
  height: 100%;
}

body {
  ::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    background: none !important;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: #cfd8dc;
    background-clip: padding-box;
    border: 4px solid rgba(0, 0, 0, 0);
    border-radius: 99999px;
  }
}

.v-select-list {
  margin-top: 10px !important;
  ::-webkit-scrollbar-track {
    background: #fff !important;
  }
}
#app {
  @extend %typography-small;
  height: 100%;
}

// ===
// Base element styles
// ===

h1 {
  @extend %typography-xxlarge;
}

h2 {
  @extend %typography-xlarge;
}

h3 {
  @extend %typography-large;
}

h4 {
  @extend %typography-medium;
}

h5,
h6 {
  @extend %typography-small;
}

// override vuetify default style globally

.v-application .text-h1.text-h1,
.v-application .text-h2.text-h2,
.v-application .text-h3.text-h3,
.v-application .text-h4.text-h4,
.v-application .text-h5.text-h5,
.v-application .text-h6.text-h6,
.v-application .text-subtitle-1.text-subtitle-1,
.v-application .text-subtitle-2.text-subtitle-2,
.v-application .text-body-1.text-body-1,
.v-application .text-body-2.text-body-2,
.v-application .text-button.text-button,
.v-application .text-caption.text-caption,
.v-application .text-overline.text-overline {
  font-family: 'Poppins', sans-serif !important;
}

.v-tooltip__content {
  max-width: 300px;
}

// Tab background none for consent banner page
.theme--light.v-tabs-items {
  width: 100%;
  background-color: rgba(0, 0, 0, 0) !important;
}

// ===
// Vendor
// ===

#nprogress .bar {
  background: var(--global-color-blue-darken-2);
}

.container {
  overflow-y: auto;
}
