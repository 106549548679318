@import './shared/styles/_vuetify-overrides.scss';

















































.cassie-application-background {
	background: var(--global-main-background-color);
}
