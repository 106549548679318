:root {
  --global-cassie-tabs-container-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  --global-cassie-tab-border-right: 2px solid #f1f1f5;
}

.preview-device-tab {
  font-size: 10px;
  color: white !important;
  text-transform: var(--cassie-tab-text-transform);
}

.preview-device-tab--active {
  border-radius: 5px;
  background-color: #5fa2e6;
}
