:root {
  --cassie-input-width-xs: 15% !important;
  --cassie-input-width-sm: 25% !important;
  --cassie-input-width-md: 50% !important;
  --cassie-input-width-lg: 75% !important;
  --cassie-input-width-xl: 100% !important;
}

.cassie-input-width-xs {
  width: var(--cassie-input-width-xs);
}
.cassie-input-width-sm {
  width: var(--cassie-input-width-sm);
}
.cassie-input-width-md {
  width: var(--cassie-input-width-md);
}
.cassie-input-width-lg {
  width: var(--cassie-input-width-lg);
}
.cassie-input-width-xl {
  width: var(--cassie-input-width-xl);
}