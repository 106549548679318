:root {
  --cassie-spacer-sm: 8px !important;
  --cassie-spacer-md: 16px !important;
  --cassie-spacer-lg: 32px !important;
}

.cassie-vertical-sm.cassie-vertical-sm > :not(:last-child){
  margin-bottom: var(--cassie-spacer-sm);
}
.cassie-vertical-md.cassie-vertical-md > :not(:last-child){
  margin-bottom: var(--cassie-spacer-md);
}
.cassie-vertical-lg.cassie-vertical-lg > :not(:last-child){
  margin-bottom: var(--cassie-spacer-lg);
}
.cassie-horizontal-sm.cassie-horizontal-sm > :not(:last-child){
  margin-right: var(--cassie-spacer-sm);
}
.cassie-horizontal-md.cassie-horizontal-md > :not(:last-child){
  margin-right: var(--cassie-spacer-md);
}
.cassie-horizontal-lg.cassie-horizontal-lg > :not(:last-child){
  margin-right: var(--cassie-spacer-lg);
}