$system-default-font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI',
  'Helvetica', 'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

$heading-font-family: $system-default-font-family;
$heading-font-weight: 600;

$content-font-family: $system-default-font-family;
$content-font-weight: 400;

%font-heading {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
}

%font-content {
  font-family: $content-font-family;
  font-weight: $content-font-weight;
}

$cassie-font: 'Poppins', sans-serif;
