:root {
  --cassie-25: 25%;
  --cassie-50: 50%;
  --cassie-75: 75%;
  --cassie-100: 100%;
}

// GRID
$size-grid-padding: 1.3rem;

// CONTENT
$size-content-width-max: 50rem;
$size-content-width-min: 25rem;

// INPUTS
$size-input-padding-vertical: 0.75em;
$size-input-padding-horizontal: 1em;
$size-input-padding: $size-input-padding-vertical $size-input-padding-horizontal;
$size-input-border: 1px;
$size-input-border-radius: (1em + $size-input-padding-vertical * 2) / 10;

// BUTTONS
$size-button-padding-vertical: $size-grid-padding / 2;
$size-button-padding-horizontal: $size-grid-padding / 1.5;
$size-button-padding: $size-button-padding-vertical
  $size-button-padding-horizontal;

// SCREEN SIZES
$size-screen-lg: 1264px;
$size-screen-xl: 1904px;


.cassie-25-w {
  width: var(--cassie-25);
}
.cassie-50-w {
  width: var(--cassie-50);
}
.cassie-75-w {
  width: var(--cassie-75);
}
.cassie-100-w {
  width: var(--cassie-100);
}
.cassie-25-h {
  height: var(--cassie-25);
}
.cassie-50-h {
  height: var(--cassie-50);
}
.cassie-75-h {
  height: var(--cassie-75);
}
.cassie-100-h {
  height: var(--cassie-100);
}
