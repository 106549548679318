:root {
  // main colours
  --global-main-background-color: #f8f9fd !important;
  --global-color-blue-darken-2: #1976d2 !important;
  --global-color-orange-darken-3: #ef6c00;
  --global-color-green: #4caf50;
  --global-color-blue-grey-lighten-4: #cfd8dc;
  --global-color-red-accent-2: #ff5252;
  --global-color-nav-background: #3a3950;
  --global-header-text-color: #35495e;

  // list card colours
  --global-cassie-list-card-border: #cfd8dc 5px solid !important;

  // input colours
  --global-cassie-disabled-button-color: rgba(0, 0, 0, 0.26) !important;
  --global-cassie-disabled-button-background-color: #fff !important;
  --global-cassie-disabled-button-border: 1px solid #9e9e9e !important;

  --global-cassie-disabled-textarea-color: rgba(0, 0, 0, 0.26) !important;
  --global-cassie-disabled-textarea-border: 1px solid rgba(0, 0, 0, 0.26) !important;
  --global-cassie-switch-enabled-color: #0178aa !important;
  --global-cassie-switch-disabled-color: rgba(0, 0, 0, 0.38) !important;

  // table colours
  --global-cassie-selected-table-row-background: #5fa2e6 !important;
  --global-cassie-selected-table-row-text-color: #fff !important;

  // settings border
  --global-cassie-settings-border: 1px solid #949fb6;
}

// endpoint colours

.endpoint-get {
  color: white;
  background-color: #61affe;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}
.endpoint-post {
  color: white;
  background-color: #49cc90;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}
.endpoint-put {
  color: white;
  background-color: #fca130;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}
.endpoint-delete {
  color: white;
  background-color: #f93e3e;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
}
